import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { GlobalParams } from 'src/app/global-params';
import { QueryParamNames, SessionObjectNames } from 'src/app/models/stringconst-names';
import { RuntimeConfiguration } from 'src/app/services/configuration-injection.service';
import { FadLinkService } from 'src/app/services/fad-link.service';
import { ApiResponse } from 'src/app/utils/api-response';
import { QuickLinksCategories } from '../../models/configuration';
import { FacetSummary, FiltersModel } from '../../models/filters';
import { LocationConverter, LocationSuggestionResult, SelectedLocation } from '../../models/location';
import { ConfigurationService } from '../../services/configuration.service';
import { CookieService } from '../../services/cookie.service';
import { LocationService } from '../../services/location.service';
import { FadUrlBuilder } from '../../utils/url/fad-url-builder';
import { UrlBuilderParams } from '../../utils/url/url-builder-params';
import { BlockitSessionService } from 'src/app/services/blockit-session.service';

@Component({
  selector: 'cs-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Input() keyword = '';
  @Input() selectedLocation: SelectedLocation;
  @Input() bookOnline = false;
  @Input() showQuicklinks = true;
  @Input() filtersModel: FiltersModel;
  @Input() fadBaseUrl = '';
  @Input() includeProviderRoleTypes = false;
  @Output() includeProviderTypeEmit = new EventEmitter<boolean>();
  @Output() searchbutttonClick = new EventEmitter<boolean>();

  runtimeConfig: RuntimeConfiguration;
  configShowQuickLinks: boolean;
  isValidSearchLocation = true;
  useNewProviderDetailsRoute;
  quickLinksCategories: QuickLinksCategories[] = [];
  isLocationRequired: boolean;
  includeProviderRoleTypesCheckbox = false;

  searchbtnClick = 'btnClickSearch';
  checkboxSearch = 'checkboxSearch';

  searchHandler = this.searchbtnClick;
  quickLinksButtonAlignment = false;
  providerSearchActive = true;
  hideProviderSuggestions = false;
  autoSuggestionLoadingMessage = '';
  specialitySelected = false;
  showSearchBtn:boolean;
  showRoundBtn:string;
  isVMFHMarket:boolean;
  blockitService: BlockitSessionService;
  configurationService: ConfigurationService;
  @ViewChild('searchFieldsGroup') searchFieldsGroup: ElementRef;
  @ViewChild('searchInputs') searchInputs: ElementRef;

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.searchInputs.nativeElement.contains(event.target)) {
      this.searchFieldsGroup.nativeElement.style.boxShadow = 'none';
    } else {
      if (window.innerWidth > 767) {
        this.searchFieldsGroup.nativeElement.style.boxShadow = '0px 2px 8px rgba(77, 82, 90, 0.3)';
      }
      if(this.showRoundBtn){
        this.searchFieldsGroup.nativeElement.style.boxShadow = 'none';
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    if (event.target.innerWidth < 768) {
      this.searchFieldsGroup.nativeElement.style.boxShadow = 'none';
    }
  }

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private urlBuilder: FadUrlBuilder,
    private locationService: LocationService,
    private fadLinkServer: FadLinkService,
    private route: ActivatedRoute,
    private injector : Injector
  ) {
    this.configurationService = this.injector.get<ConfigurationService>(ConfigurationService);
    this.blockitService = this.injector.get<BlockitSessionService>(BlockitSessionService);
  }

  ngOnInit(): void {
    this.blockitService.getBlockItURL();
    this.runtimeConfig = this.configurationService.getRuntimeConfiguration();
    this.configShowQuickLinks = this.configurationService.showquickLinks();
    this.useNewProviderDetailsRoute = this.configurationService.UseNewProviderDetailsRoute();

    this.quickLinksCategories = this.configurationService.quickLinksCategories();
    this.includeProviderRoleTypesCheckbox = this.configurationService.includeProviderRoleCheckBox();
    this.quickLinksButtonAlignment = this.configurationService.ShowButtonsbyRows();
    this.autoSuggestionLoadingMessage = this.configurationService.getAutoSuggestionLoadingMessage();
    this.showSearchBtn = this.configurationService.showSearchButton();
    this.showRoundBtn= this.configurationService.getSearchButton();
    this.isVMFHMarket = this.configurationService.getMarketCodes().includes('vmfh');
  }

  getQuickLinksResults(quickLink: QuickLinksCategories): void {
    switch (quickLink.type.toLowerCase()) {
      case 'keywords':
        this.quickLinksKeywordsSearch(quickLink);
        break;
      case 'specialities':
        this.quickLinksSpecialitiesSearch(quickLink.value);
        break;
      case 'affiliations':
        this.quickLinksHospitalAffiliationsSearch(quickLink.value);
        break;
      default:
        this.quickLinksSearch(quickLink);
        break;
    }
  }

  private quickLinksKeywordsSearch(quickLink: QuickLinksCategories) {
    this.resetAndSetKeywords(quickLink.value);
    const queryParams: Params = {};
    this.filterQuickLinkMedGroups(quickLink.medGroup);
    this.buildSearchAndNavigate(queryParams);
  }

  private quickLinksSpecialitiesSearch(specialties: string) {
    this.resetAndSetKeywords('');
    const queryParams: Params = {};
    if (specialties.includes('|')) {
      queryParams.specialties = specialties;
      this.buildSearchAndNavigate(queryParams);
    } else {
      this.keyword = specialties;
      this.buildSearchAndNavigate(queryParams, true);
    }
  }

  private quickLinksHospitalAffiliationsSearch(affiliations: string) {
    this.resetAndSetKeywords('');
    const queryParams: Params = {};

    queryParams.affiliations = affiliations.replace("'", '');

    this.buildSearchAndNavigate(queryParams);
  }

  private quickLinksSearch(quickLink: QuickLinksCategories) {
    const quickLinkType= quickLink?.type;
    this.resetAndSetKeywords('');
    const queryParams: Params = {};
    if (quickLinkType === 'videovisits') {
      queryParams.videovisits = true;
    }
    if (quickLinkType === 'bookonline') {
      queryParams.bookonline = true;
    }
    if (quickLinkType === 'acceptingnew') {
      queryParams.acceptingnew = true;
      this.filterQuickLinkMedGroups(quickLink.medGroup);
    }
    if (quickLinkType === 'allVisits') {
      queryParams.allVisits = true;
    }
    if (quickLinkType === 'insurances') {
      queryParams.insurances = true;
    }
    this.buildSearchAndNavigate(queryParams);
  }

  filterQuickLinkMedGroups(medgroups: string[]): void{
    if (medgroups && medgroups.length > 1) {
    const selectedMedGroups: FacetSummary[] = [];
    medgroups.forEach(x=> {
      const selectedMedgroup: FacetSummary = {
          name: x,
          count: 0,
          selected: true
        };
        selectedMedGroups.push(selectedMedgroup);
    });
    this.filtersModel.providerMedicalGroup = selectedMedGroups;
    }
  }

  /**
   * The way this is written, it's possible to double navigate. It would be better to have one thing that builds up the urls,
   * and only navigate once. Do not call navigate more than once in the method.
   */

  keyEnterHandler(value): void {
    this.specialitySelected = false;
    this.searchHandler = this.searchbtnClick;
    this.getSearchResults(value);
  }

  keywordFromInput(value): void {
    this.specialitySelected = false;
    this.keyword = value;
  }

  getSearchResults(searchTerm = '', source = 'search'): void {
    this.resetAndSetKeywords(searchTerm !== '' ? searchTerm : this.keyword);
    let queryParams: Params = {};
    if (this.searchHandler === this.checkboxSearch) {
      const snapshot = this.route.snapshot;
      queryParams = { ...snapshot.queryParams };
    }
    this.setKeywordQueryParams(queryParams);
    this.setLocationQueryParams(queryParams);
    
    if (this.includeProviderRoleTypes) {
      queryParams.includeProviderRoleTypes = this.includeProviderRoleTypes;
    }
    this.filtersModel.pageNumber = 1;

    if ((queryParams.includeProviderRoleTypes === 'true' || queryParams.includeProviderRoleTypes) && !this.includeProviderRoleTypes) {
      delete queryParams.includeProviderRoleTypes;
    }

    if (sessionStorage.getItem('LocationIdFromFAL') && sessionStorage.getItem('LocationIdFromFAL') !== '' && source === 'checkbox') {
      const locationId = sessionStorage.getItem('LocationIdFromFAL');
      const pathname = window.location.pathname;
      const baseUrl = this.configurationService.getRuntimeConfiguration().fadBaseUrl;
      const marketCode = this.configurationService.getMarketCodes()[0];
      const isHospitalBased = this.route.snapshot.queryParams.isHospitalBased;
      if (pathname.includes('/l/location-')) {
        let url = `${baseUrl}${marketCode}/l/location-${locationId}?FALredirecttoFAD=true${
          isHospitalBased === 'true' ? '' : '&isHospitalBased=true'
        }`;
        if (this.configurationService.getEmbedSource() === 'cerner') {
          url = `${
            this.runtimeConfig.fadBaseUrl
          }${this.configurationService.getRedirectToFADinCerner()}/l/location-${locationId}?FALredirecttoFAD=true${
            isHospitalBased === 'true' ? '' : '&isHospitalBased=true'
          }`;
        } else if (!this.fadLinkServer.isSelfHosted() || this.configurationService.getEmbedSource() === 'mychart') {
          if (this.configurationService.getEmbedSource() === 'mychart') {
            url = `${this.configurationService.getRedirectURLPathForFADEpic()}/l/location-${locationId}?FALredirecttoFAD=true${
              isHospitalBased === 'true' ? '' : '&isHospitalBased=true'
            }`;
          } else {
            url = `${this.configurationService.getRedirectInAEM()}/l/location-${locationId}?FALredirecttoFAD=true${
              isHospitalBased === 'true' ? '' : '&isHospitalBased=true'
            }`;
          }
        }
        window.open(url, '_self');
        return;
      }
    }

    this.handleSpecialtyRemove();

    if (this.filtersModel.providerMedicalGroup) {
      sessionStorage.setItem('includeProviderRoleTypes', 'false');
    }
    
    this.buildSearchAndNavigate(queryParams, this.specialitySelected);
  }

  private handleSpecialtyRemove():void {
    if(this.specialitySelected) {
      window.sessionStorage.removeItem(SessionObjectNames.LocationSessionId);
    }
    
    if(this.filtersModel.selectedSpecialties && this.filtersModel.selectedSpecialties.length <= 0) {
      window.sessionStorage.removeItem(SessionObjectNames.SingleSpeciality);
    }

  }

  private setKeywordQueryParams(queryParams:Params):void{
    const Keyword=this.keyword.trim().toLowerCase().replace(/\s/g, '');

    switch(Keyword){
      case 'videovisits':
          queryParams.videovisits = true;
          this.keyword = '';
          break;

      case 'bookonline':
        case 'onlinescheduling':
          queryParams.bookonline = true;
          this.keyword = '';
          break;

      case 'allVisits':
          queryParams.allVisits = true;
          this.keyword = '';
          break;

      case 'insurances':
          queryParams.insurances = true;
          this.keyword = '';
          break;

      case 'acceptingnewpatients':
          queryParams.acceptingnew = true;
          this.keyword = '';
          break;
    }

  }
  private setLocationQueryParams(queryParams:Params):void{
    if (
      this.selectedLocation?.city &&
      this.selectedLocation?.state &&
      this.filtersModel.providerMedicalGroup &&
      this.filtersModel.providerMedicalGroup.length > 0
    ) {
      queryParams.location = `${this.selectedLocation.city.toLowerCase()}-${this.selectedLocation.state.toLowerCase()}`;
    } else if (this.selectedLocation?.name && this.filtersModel.providerMedicalGroup && this.filtersModel.providerMedicalGroup.length > 0) {
      queryParams.location = `${this.selectedLocation.name.toLowerCase()}`;
    }
  }

  goToProvider($event) {
    if (this.useNewProviderDetailsRoute) {
      this.router
        .navigateByUrl(
          `/${$event.primarySpecialty.makeURLFriendly()}/${$event.firstName.makeURLFriendly()}-${$event.lastName.makeURLFriendly()}-${
            $event.npi
          }`
        )
        .then();
      return;
    }
    this.router.navigateByUrl(`/${$event.npi}-${$event.firstName.makeURLFriendly()}-${$event.lastName.makeURLFriendly()}`).then();
  }

  private resetAndSetKeywords(keywords: string) {
    window.sessionStorage.removeItem(SessionObjectNames.LocationSessionId);
    this.keyword = keywords;
    if (!this.filtersModel?.providerMedicalGroup) {
      this.filtersModel = new FiltersModel(this.configurationService);
    }
  }

  private buildSearchAndNavigate(queryParams: Params, specialtySearch = false) {
    if (!this.selectedLocation?.name) {
      this.isLocationRequired = this.configurationService.getLocationRequiredDefaultValue();
      if (this.isLocationRequired) {
        this.selectedLocation = LocationConverter.convertTo(this.cookieService.getLocationCookieFromBrowser());
        if (!this.selectedLocation) {
          this.locationService
            .getLocationByIP()
            .pipe(
              map((response) => {
                return LocationConverter.convertTo(response.result.location);
              })
            )
            .subscribe((defaultLocation) => {
              this.selectedLocation = defaultLocation;
              this.validateLocation(queryParams, this.selectedLocation, specialtySearch);
            });
        } else {
          this.validateLocation(queryParams, this.selectedLocation, specialtySearch);
        }
      } else {
        this.validateLocation(queryParams, this.selectedLocation, specialtySearch);
      }
    } else {
      this.validateLocation(queryParams, this.selectedLocation, specialtySearch);
    }
  }

  private validateLocation(queryParams: Params, location: SelectedLocation, specialtySearch = false): void {
    if (!!location && location?.name?.trim() !== '') {
      this.locationService
        .getTypedLocationSuggestions(location.name)
        .subscribe((suggestionResponse: ApiResponse<LocationSuggestionResult[]>) => {
          GlobalParams.isNeighborhoodSearch = this.checkForNeighborhoodLocation(location);

          if (suggestionResponse.isValid && suggestionResponse.result.length > 0) {
            this.isValidSearchLocation = true;
            this.navigateRoute(queryParams, this.selectedLocation, specialtySearch);
          } else {
            this.isValidSearchLocation = false;
          }
        });
    } else if ((location === undefined || location?.name === '' || location == null) && !this.isLocationRequired) {
      this.isValidSearchLocation = true;
      this.navigateRoute(queryParams, this.selectedLocation, specialtySearch);
    } else {
      this.isValidSearchLocation = false;
    }
  }

  private navigateRoute(queryParams: Params, location: SelectedLocation, specialtySearch = false): void {
    this.cookieService.addLocationCookieToBrowser(location);
    const urlQueryParams = new URLSearchParams(window.location.search);
    const specialtiesQueryParam = urlQueryParams.get(QueryParamNames.Specialties);

    const singleSpecialty = window.sessionStorage.getItem(SessionObjectNames.SingleSpeciality);
    if (queryParams === null) {
      queryParams = {};
    }
   
    let url = '';
    if (specialtiesQueryParam == null && singleSpecialty && singleSpecialty !== '' && this.keyword === '') {
      specialtySearch = true;
      this.keyword = singleSpecialty;
    } else {
      window.sessionStorage.removeItem(SessionObjectNames.SingleSpeciality);
    }

    if (this.filtersModel.providerMedicalGroup && this.filtersModel.providerMedicalGroup.length > 0) {
      url = this.urlBuilder.build(
        {
          medGroup:
            this.filtersModel.providerMedicalGroup?.length > 1
              ? this.filtersModel.providerMedicalGroup.map((x) => x.name).join('|')
              : this.filtersModel.providerMedicalGroup[0].name,
          searchTerm: specialtySearch ? null : this.keyword,
          selectedLocation: location,
          specialty: specialtySearch ? this.keyword : null
        } as UrlBuilderParams,
        this.fadBaseUrl
      );
    } else {
      url = this.urlBuilder.build(
        {
          medGroup: '',
          searchTerm: specialtySearch ? null : this.keyword,
          selectedLocation: location,
          specialty: specialtySearch ? this.keyword : null
        } as UrlBuilderParams,
        this.fadBaseUrl
      );
    }

    if (specialtiesQueryParam != null) {
      window.sessionStorage.removeItem(SessionObjectNames.SingleSpeciality);
      queryParams.specialties = specialtiesQueryParam;
    }

    queryParams = this.getPreFilterValues(urlQueryParams, queryParams);

    if (specialtySearch) {
      this.keyword = '';
    }
    this.router.navigate([url], {
      queryParams
    });
  }

  getPreFilterValues(urlQueryParams: URLSearchParams, queryParams: Params): Params {
    const genderQueryParam = urlQueryParams.get(QueryParamNames.Gender);
    const languageQueryParam = urlQueryParams.get(QueryParamNames.Languages);
    const bookonlineQueryParam =  urlQueryParams.get(QueryParamNames.BookOnline);
    const acceptingnewQueryParam =  urlQueryParams.get(QueryParamNames.AcceptingNew);
    const videovisitsQueryParam =  urlQueryParams.get(QueryParamNames.VideoVisits);
    const allVisitsQueryParam = urlQueryParams.get(QueryParamNames.AllVisits);
    const insurancesQueryParam = urlQueryParams.get(QueryParamNames.Insurances);
    const distanceQueryParam = urlQueryParams.get(QueryParamNames.Distance);
    const affiliationsQueryParam = urlQueryParams.get(QueryParamNames.Affiliations);

    if(languageQueryParam !=null) {
      queryParams.languages = languageQueryParam;
    }
    if(bookonlineQueryParam !=null) {
      queryParams.bookonline = bookonlineQueryParam;
    }
    if(acceptingnewQueryParam !=null) {
      queryParams.acceptingnew = acceptingnewQueryParam;
    }
    if(videovisitsQueryParam !=null) {
      queryParams.videovisits = videovisitsQueryParam;
    }
    if(allVisitsQueryParam !=null) {
      queryParams.allVisits = allVisitsQueryParam;
    }
    if(insurancesQueryParam !=null) {
      queryParams.insurances = insurancesQueryParam;
    }
    if(distanceQueryParam !=null) {
      queryParams.distance = distanceQueryParam;
    }
    if (genderQueryParam != null) {
      queryParams.gender = genderQueryParam;
    }
    if (affiliationsQueryParam != null) {
      queryParams.affiliations = affiliationsQueryParam;
    }
    return queryParams;
  }
  goToFilters($event: string): void {
    this.filtersModel = new FiltersModel(this.configurationService);
    this.keyword = `${$event}`;
    this.specialitySelected = true;
  }

  checkForNeighborhoodLocation(location: SelectedLocation): boolean {
    if (location.neighborhood || (location.name && location.name.split(',').length >= 3)) {
      return true;
    } else {
      return false;
    }
  }

  providerRoleTypesSelected($event: boolean): void {
    this.includeProviderRoleTypes = $event;
    this.searchHandler = this.checkboxSearch;
    this.includeProviderTypeEmit.emit(this.includeProviderRoleTypes);
    sessionStorage.setItem('includeProviderRoleTypes', String($event));
    this.getSearchResults('', 'checkbox');
  }

  getSearchResultsBtnclk(): void {
    this.isValidSearchLocation = true;
    this.searchHandler = this.searchbtnClick;
    this.getSearchResults();
    this.searchbutttonClick.emit(true);
  }

  setProviderSearchActive(): void {
    this.providerSearchActive = true;
    this.hideProviderSuggestions = false;
  }

  closeProviderAutoSuggestionContainer(): void {
    if (this.providerSearchActive) {
      this.hideProviderSuggestions = true;
    }
  }

}
