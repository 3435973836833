<div
  [ngClass]="{ active: showModal || showCloseIcon, 'filter-button': !showSortAndFilters, showSortAndFiltersHeader: showSortAndFilters}"
  role="button"
  tabindex="0"
  [attr.aria-expanded]="showModal"
>
  <span *ngIf="!showSortAndFilters" (click)="filtersClicked()">Filters</span>
  <svg
    *ngIf="!showSortAndFilters && showCloseIcon"
    class="clear-filter"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    (click)="clearFilters($event)"
    [attr.aria-hidden]="showCloseIcon"
    [attr.aria-label]="'Clear all filters'"
    tabindex="0"
  >
    <path d="M1 1L15 15" stroke-width="2" />
    <path d="M1 14.998L15 1" stroke-width="2" />
  </svg>
  <div *ngIf="showSortAndFilters && showMobileFiltersForLeftDesign()">
    <span *ngIf="showSortAndFilters" [ngClass]="{ showSortAndFiltersHeader: showSortAndFilters}" (click)="filtersClicked()">
      Sort and Filters
      <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.34158 10.2714C0.417829 10.5193 -0.143893 11.337 0.0343635 12.1719C0.182604 12.8719 0.859489 13.4228 1.6791 13.5106C2.48217 13.5943 3.2975 13.1829 3.64054 12.5173C4.88282 12.5202 6.12449 12.5202 7.36493 12.5196H13.1322C13.1996 12.5196 13.2676 12.519 13.3356 12.5121C13.5451 12.4926 13.7368 12.3934 13.8606 12.2425C13.9518 12.1288 14.0015 11.9951 14.0015 11.8592C14.0015 11.8121 13.9953 11.7645 13.9831 11.7168C13.901 11.3932 13.599 11.1918 13.1953 11.1918C10.01 11.1918 6.82404 11.1918 3.62155 11.1924C3.23624 10.4223 2.26165 10.0258 1.34158 10.2714ZM2.65063 11.8597C2.65063 12.2264 2.31127 12.5242 1.89411 12.5242C1.47634 12.5242 1.13759 12.2264 1.13759 11.8597C1.13759 11.4931 1.47634 11.1958 1.89411 11.1958C2.31127 11.1958 2.65063 11.4931 2.65063 11.8597Z"
          fill="#0071C2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.88827 5.61765C5.61934 5.79609 5.40984 6.03126 5.26528 6.31724L1.51815 6.31546L0.772029 6.31605C0.571104 6.31605 0.398357 6.37278 0.259303 6.48622C0.0179477 6.67884 -0.0622998 6.97014 0.0498016 7.24371C0.160678 7.51433 0.433887 7.68272 0.76284 7.6839H5.26711C5.60403 8.33622 6.17801 8.69074 6.92658 8.70964C7.74805 8.73151 8.36981 8.37994 8.73368 7.68154C9.68318 7.6839 10.6327 7.6839 11.5815 7.6839L12.4728 7.68449C12.7381 7.68509 13.0052 7.68568 13.2716 7.68154C13.4983 7.6774 13.7078 7.58168 13.8462 7.41861C13.9473 7.29807 14 7.1539 14 7.00559C14 6.94946 13.992 6.89274 13.9767 6.83601C13.8854 6.51045 13.5951 6.31605 13.1981 6.31605C11.7163 6.31546 10.2351 6.31487 8.72204 6.30306C8.49539 5.83273 8.04882 5.48589 7.49444 5.3494C6.93516 5.2135 6.35015 5.31159 5.88827 5.61765ZM7.75662 6.99968C7.75662 7.37843 7.41787 7.68568 7.00009 7.68568C6.58231 7.68568 6.24295 7.37843 6.24295 6.99968C6.24295 6.62035 6.58231 6.31428 7.00009 6.31428C7.41787 6.31428 7.75662 6.62035 7.75662 6.99968Z"
          fill="#0071C2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.6599 3.72904C13.5837 3.48273 14.1454 2.66966 13.9671 1.84119C13.8189 1.14501 13.142 0.597639 12.3218 0.510972C11.5194 0.426586 10.7035 0.835972 10.3605 1.49738C9.11825 1.49452 7.87663 1.49452 6.63563 1.49567H0.86858C0.8012 1.49567 0.732596 1.49567 0.664604 1.50251C0.455116 1.52189 0.263392 1.62053 0.139659 1.77049C0.0483905 1.88338 0 2.01624 0 2.15137C0 2.19812 0.00551285 2.24602 0.0171511 2.29277C0.0992312 2.61435 0.401213 2.81448 0.804876 2.81448C3.99069 2.81505 7.1765 2.81505 10.3807 2.81391C10.7648 3.57909 11.7405 3.97308 12.6599 3.72904ZM11.3503 2.1508C11.3503 1.78645 11.6897 1.49053 12.1074 1.49053C12.5252 1.49053 12.8645 1.78645 12.8645 2.1508C12.8645 2.51571 12.5252 2.81049 12.1074 2.81049C11.6897 2.81049 11.3503 2.51571 11.3503 2.1508Z"
          fill="#0071C2"
        />
      </svg>
    </span>
  </div>
</div>
<dhcl-modal
  [alignTop]="alignTop"
  [show]="showModal"
  (closeClicked)="showModal = !showModal"
  [closeOnOverlay]="true"
  [customClass]="isMobileCernerPage ? 'mobile-filters-modal cerner-mobile-filters-modal' : 'mobile-filters-modal'"
  role="dialog"
  aria-label="Sort and Filters"
>
  <div class="dialog-title">
    <div
      *ngIf="!resizeService.isTabletViewport"
      [attr.aria-disabled]="!isFilterSelected"
      tabindex="0"
      role="button"
      [attr.aria-label]="'Clear all filters'"
      class="clear-all-button"
      [ngClass]="{ active: isFilterSelected }"
      (click)="clearFilters()"
    >
      Clear all
    </div>
    <h2 class="h2" *ngIf="!showSortAndFilters">Filters</h2>
    <h2 class="h2" *ngIf="showSortAndFilters">Sort and Filters</h2>
  </div>
  <div class="dialog" [style.max-height.px]="dialogHeight">
    <div *ngIf="showSortAndFilters" tabindex="0" role="dialog" aria-label="Sort By" class="dialog-page-content p p2">
      <div class="sort-by-drop-down">
        <div>Sort By</div>
        <dhcl-dropdown
          [placeholder]="'Select'"
          [dropdowns]="sortDropdowns"
          [selectedValue]="selectedSortBy"
          (changed)="handleSortChange($event)"
        >
        </dhcl-dropdown>
      </div>
    </div>
    <div
      class="online-scheduling"
      role="checkbox"
      [attr.aria-label]="'Online scheduling'"
      [attr.aria-checked]="filtersModel.onlineSchedulingToggle"
      tabindex="0"
      *ngIf="showOnlineSchedulingFilter && filtersModel.onlineSchedulingProvidersCount > 0"
    >
      <div
        *ngIf="!showSortAndFilters"
        class="section-header toggle-col-option"
        (click)="filtersModel.onlineSchedulingToggle = !filtersModel.onlineSchedulingToggle"
      >
        <span>{{onlineSchedulingbuttonText}} <span class="option-count">({{ filtersModel.onlineSchedulingProvidersCount }})</span></span>
        <span class="option-checkbox">
          <svg class="unchecked" *ngIf="!filtersModel.onlineSchedulingToggle" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
          </svg>
          <svg class="checked" *ngIf="filtersModel.onlineSchedulingToggle" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="3" />
            <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
          </svg>
        </span>
      </div>
      <div
        *ngIf="showSortAndFilters"
        class="section-header toggle-col-option"
        (click)="filtersModel.onlineSchedulingToggle = !filtersModel.onlineSchedulingToggle"
      >
        <span>{{onlineSchedulingbuttonText}} <span class="option-count">({{ filtersModel.onlineSchedulingProvidersCount }})</span></span>
        <cs-slide-toggle [disabled]="filtersModel.onlineSchedulingProvidersCount === 0" [checked]="filtersModel.onlineSchedulingToggle">
        </cs-slide-toggle>
      </div>
    </div>

    <div
      class="video-visits"
      role="checkbox"
      [attr.aria-label]="'Video Visits'"
      [attr.aria-checked]="filtersModel.videoVisitsToggle"
      *ngIf="showVideoVisitsFilter && filtersModel.videoVisitsProvidersCount > 0"
    >
      <div
        *ngIf="!showSortAndFilters"
        class="section-header toggle-col-option"
        (click)="filtersModel.videoVisitsToggle = !filtersModel.videoVisitsToggle"
      >
        <span>{{videoVisitButtonText}} <span class="option-count">({{ filtersModel.videoVisitsProvidersCount }})</span></span>
        <span class="option-checkbox">
          <svg class="unchecked" *ngIf="!filtersModel.videoVisitsToggle" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
          </svg>
          <svg class="checked" *ngIf="filtersModel.videoVisitsToggle" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="3" />
            <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
          </svg>
        </span>
      </div>
      <div
        *ngIf="showSortAndFilters"
        class="section-header toggle-col-option"
        (click)="filtersModel.videoVisitsToggle = !filtersModel.videoVisitsToggle"
      >
        <span>{{videoVisitButtonText}} <span class="option-count">({{ filtersModel.videoVisitsProvidersCount }})</span></span>
        <cs-slide-toggle [disabled]="filtersModel.videoVisitsProvidersCount === 0" [checked]="filtersModel.videoVisitsToggle">
        </cs-slide-toggle>
      </div>
    </div>

    <div
      class="online-scheduling-all-visit"
      role="checkbox"
      [attr.aria-label]="'Online scheduling-all visits'"
      [attr.aria-checked]="filtersModel.onlineSchedulingAllVisitToggle"
      tabindex="0"
      *ngIf="isDignityHealthDivision && showOnlineSchedulingAllVisitFilter && filtersModel.onlineSchedulingAllVisitProvidersCount > 0"
    >
      <div
        *ngIf="!showSortAndFilters"
        class="section-header toggle-col-option"
        (click)="filtersModel.onlineSchedulingAllVisitToggle = !filtersModel.onlineSchedulingAllVisitToggle"
      >
        <span
          >Online scheduling-all visits <span class="option-count">({{ filtersModel.onlineSchedulingAllVisitProvidersCount }})</span></span
        >
        <span class="option-checkbox">
          <svg
            class="unchecked"
            *ngIf="!filtersModel.onlineSchedulingAllVisitToggle"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
          </svg>
          <svg class="checked" *ngIf="filtersModel.onlineSchedulingAllVisitToggle" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="3" />
            <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
          </svg>
        </span>
      </div>
      <div
        *ngIf="showSortAndFilters"
        class="section-header toggle-col-option"
        (click)="filtersModel.onlineSchedulingAllVisitToggle = !filtersModel.onlineSchedulingAllVisitToggle"
      >
        <span
          >Online scheduling-all visits <span class="option-count">({{ filtersModel.onlineSchedulingAllVisitProvidersCount }})</span></span
        >
        <cs-slide-toggle
          [disabled]="filtersModel.onlineSchedulingAllVisitProvidersCount === 0"
          [checked]="filtersModel.onlineSchedulingAllVisitToggle"
        >
        </cs-slide-toggle>
      </div>
    </div>

    <div
      class="accepting-new-patients"
      role="checkbox"
      [attr.aria-label]="'Accepting new patients'"
      [attr.aria-checked]="filtersModel.newPatientsToggle"
      *ngIf="showAcceptingNewPatientsFilter && filtersModel.newPatientsProvidersCount > 0"
    >
      <div
        *ngIf="!showSortAndFilters"
        class="section-header toggle-col-option"
        (click)="filtersModel.newPatientsToggle = !filtersModel.newPatientsToggle"
      >
        <span>Accepting new patients <span class="option-count">({{ filtersModel.newPatientsProvidersCount }})</span></span>
        <span class="option-checkbox">
          <svg class="unchecked" *ngIf="!filtersModel.newPatientsToggle" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
          </svg>
          <svg class="checked" *ngIf="filtersModel.newPatientsToggle" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="3" />
            <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
          </svg>
        </span>
      </div>
      <div
        *ngIf="showSortAndFilters"
        class="section-header toggle-col-option"
        (click)="filtersModel.newPatientsToggle = !filtersModel.newPatientsToggle"
      >
        <span>Accepting new patients <span class="option-count">({{ filtersModel.newPatientsProvidersCount }})</span></span>
        <cs-slide-toggle [disabled]="filtersModel.newPatientsProvidersCount === 0" [checked]="filtersModel.newPatientsToggle">
        </cs-slide-toggle>
      </div>
    </div>

    <div class="location-name" [attr.aria-label]="'Location'" *ngIf="filtersModel.locationName && showLocationName">
      <div class="section-header">
        <span>Location</span>
        <div class="close-icon" (click)="filterLocationName()">
          <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2  2 L16 16 M2 16 L16 2" />
          </svg>
        </div>
      </div>
      <div class="section-content">{{ filtersModel.locationName }}</div>
    </div>

    <cs-accordion [title]="'Availability'" *ngIf="showAvailabilityFilter">
      <div class="patient-availability">
        <cs-availability-dialog [filtersModel]="filtersModel"> </cs-availability-dialog>
      </div>
    </cs-accordion>

    <cs-accordion [title]="'Insurances accepted'" *ngIf="showInsuranceAcceptedFilter">
      <div class="toggle-two-col-list">
        <div
          *ngFor="let insurance of filtersModel.availableInsurances"
          class="toggle-col-option"
          (click)="toggleInsurance(insurance)"
          [attr.aria-expanded]="insurance"
          tabindex="0"
        >
          <span class="option-checkbox">
            <svg class="unchecked" *ngIf="!checkInsurance(insurance)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
            </svg>
            <svg class="checked" *ngIf="checkInsurance(insurance)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="3" />
              <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
            </svg>
          </span>
          {{ insurance.name }}&nbsp;<span class="option-count">({{ insurance.count }})</span>
        </div>
      </div>
    </cs-accordion>

    <cs-accordion [title]="'Specialty'" *ngIf="showSpecialtyFilter">
      <div class="toggle-two-col-list">
        <div
          *ngFor="let specialty of filtersModel.availableSpecialties"
          class="toggle-col-option"
          (click)="toggleSpecialty(specialty)"
          [attr.aria-expanded]="specialty"
          tabindex="0"
        >
          <span class="option-checkbox">
            <svg class="unchecked" *ngIf="!checkSpecialty(specialty)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
            </svg>
            <svg class="checked" *ngIf="checkSpecialty(specialty)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="3" />
              <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
            </svg>
          </span>
          {{ specialty.name }}&nbsp;<span class="option-count">({{ specialty.count }})</span>
        </div>
      </div>
    </cs-accordion>

    <cs-accordion
      *ngIf="showMedicalGroupFilter && medicalGroupHoverText !== '' && !multipleMedicalGroupSelection"
      [title]="'Medical group'"
      [tooltip]="medicalGroupHoverText"
      [tooltipInfo]="'More information about medical group'"
    >
      <div *ngIf="medicalGroupToolTip !== ''" class="medical-group-filter-copy">{{ medicalGroupToolTip }}</div>
      <div class="medical-group-filter-dropdown">
        <dhcl-dropdown
          [placeholder]="'Select Medical group'"
          [dropdowns]="filtersModel.medicalGroupList"
          [selectedValue]="selectedMedicalGroupName"
          (changed)="selectMedicalGroup($event)"
        >
        </dhcl-dropdown>
      </div>
    </cs-accordion>

    <cs-accordion
      *ngIf="showMedicalGroupFilter && medicalGroupHoverText !== '' && multipleMedicalGroupSelection"
      [title]="'Medical group'"
      [tooltip]="medicalGroupHoverText"
      [tooltipInfo]="'More information about medical group'"
    >
      <div class="toggle-two-col-list">
        <div *ngFor="let medgroupItem of filtersModel.medicalGroupList" class="toggle-col-option" (click)="toggleMedgroup(medgroupItem)">
          <span class="option-checkbox">
            <svg class="unchecked" *ngIf="!checkMedgroup(medgroupItem)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
            </svg>
            <svg class="checked" *ngIf="checkMedgroup(medgroupItem)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="3" />
              <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
            </svg>
          </span>
          {{ medgroupItem.label }}
        </div>
      </div>
    </cs-accordion>

    <cs-accordion
      [title]="'Hospital Affiliation'"
      *ngIf="showAffiliationFilter && affiliationHoverText !== ''"
      [tooltip]="affiliationHoverText"
    >
      <div *ngIf="affiliationToolTip !== ''" class="medical-group-filter-copy">{{ affiliationToolTip }}</div>
      <div class="toggle-two-col-list">
        <div *ngFor="let hospital of filtersModel.availableHospitals" class="toggle-col-option" (click)="toggleHospital(hospital)">
          <span class="option-checkbox">
            <svg class="unchecked" *ngIf="!checkHospital(hospital)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
            </svg>
            <svg class="checked" *ngIf="checkHospital(hospital)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="3" />
              <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
            </svg>
          </span>
          {{ hospital.name }}&nbsp;<span class="option-count">({{ hospital.count }})</span>
        </div>
      </div>
    </cs-accordion>

    <cs-accordion [title]="'Gender'" *ngIf="showGenderFilter">
      <div class="gender" role="radiogroup" aria-label="Select Gender">
        <div
          class="radio-button-option"
          (click)="selectGender('female')"
          role="radio"
          [attr.aria-checked]="filtersModel.genderSelect === 'female'"
          tabindex="0"
        >
          <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
            <path
              *ngIf="filtersModel.genderSelect === 'female'"
              d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
            />
          </svg>
          Female&nbsp;
          <span>({{ filtersModel.femaleCount }})</span>
        </div>
        <div
          class="radio-button-option"
          (click)="selectGender('male')"
          role="radio"
          [attr.aria-checked]="filtersModel.genderSelect === 'male'"
          tabindex="0"
        >
          <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
            <path
              *ngIf="filtersModel.genderSelect === 'male'"
              d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
            />
          </svg>
          Male&nbsp;
          <span>({{ filtersModel.maleCount }})</span>
        </div>
        <div
          *ngIf="isVMFHMarket"
          class="radio-button-option"
          (click)="selectGender('non-binary')"
          role="radio"
          [attr.aria-checked]="filtersModel.genderSelect === 'non-binary'"
          tabindex="0"
        >
          <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
            <path
              *ngIf="filtersModel.genderSelect === 'non-binary'"
              d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
            />
          </svg>
          Non-Binary&nbsp;
          <span>({{ filtersModel.nonbinaryCount }})</span>
        </div>
        <div
          *ngIf="isVMFHMarket"
          class="radio-button-option"
          (click)="selectGender('other')"
          role="radio"
          [attr.aria-checked]="filtersModel.genderSelect === 'other'"
          tabindex="0"
        >
          <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
            <path
              *ngIf="filtersModel.genderSelect === 'other'"
              d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
            />
          </svg>
          Other&nbsp;
          <span>({{ filtersModel.otherCount }})</span>
        </div>
      </div>
    </cs-accordion>

    <cs-common-more-filters
      [filtersModel]="filtersModel"
      [showInsuranceFilter]="showInsuranceFilter"
      [showLanguageFilter]="showLanguageFilter"
    ></cs-common-more-filters>

    <cs-accordion [title]="'Search radius'" *ngIf="showSearchRadiusFilter && selectedLocation">
      <div class="range-header">Only show providers within<span class="range-number">{{ displayRadius }}</span>mi of search location</div>
      <div class="range-slider-container">
        <input type="range" [(ngModel)]="filtersModel.searchRadius" min="0" max="100" value="filtersModel.searchRadius" step="20" />
        <div
          class="range-slider-fill"
          [ngStyle]="{
            background:
              'linear-gradient(90deg, var(--dhcl-color-secondary) 0%, var(--dhcl-color-secondary) ' +
              filtersModel.searchRadius +
              '%, #e3e1dc ' +
              filtersModel.searchRadius +
              '%)'
          }"
        ></div>
        <div class="range-indicators">
          <div class="first">1</div>
          <div class="middle">
            <div>5</div>
            <div>10</div>
            <div>25</div>
            <div>50</div>
          </div>
          <div class="last">100</div>
        </div>
      </div>
    </cs-accordion>
  </div>
  <div class="dialog-footer">
    <div
      *ngIf="isTabletViewport"
      role="button"
      aria-label="Clear all filters"
      [attr.aria-disabled]="!isFilterSelected"
      class="clear-all-button"
      [ngClass]="{ active: isFilterSelected }"
      (click)="clearFilters()"
    >
      Clear all
    </div>
    <button dhclButton (click)="applyFilters()" role="button" [ngClass]="{ mobile: !isTabletViewport }">Apply</button>
  </div>
</dhcl-modal>
